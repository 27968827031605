<template>
  <topBanner />
  <div class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="sigma_about mt-5 mt-lg-0">
            <h3 class="title">{{ conversionData.title }}</h3>
            <div class="sigma_about-content mt-5">
              <p v-for="item in conversionData.desc" :key="item.id">{{ item.row }}</p>
            </div> 
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section section-padding bg-gray">
    <div class="container">
      <div class="section-title centered">
        <span class="subtitle1">{{ conversionData.conversion_services.subtitle }}</span>
        <h3 class="title">{{ conversionData.conversion_services.title }}</h3>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6" v-for="item in conversionData.conversion_services.services" :key="item.id">
          <div class="sigma_service style-6">
            <div class="sigma_service-thumb">
              <img :src="getItem(item.image)" alt="img" />
            </div>
            <div class="sigma_service-body">
              <h5>
                <a href="javascript:void(0)">{{ item.title }}</a>
              </h5>
              <p>
                {{ item.desc }}
              </p> 
            </div>
          </div>
        </div> 
      </div> 
    </div>
  </div>
</template>

<script>
import data from '../Data/data.json'
import topBanner from "../components/innerpagestopbanner/innerTop.vue";
export default {
  components: {
    topBanner,
  },
  data() {
    return {
      conversionData: ''
    };
  },
  created(){
    this.conversionData = data.Conversions
  },
  methods: {
    getItem(pic){
      if(pic){
        return require('../assets/img/convirsion/' + pic)
      } else{
        return null
      }
    }
  }
};
</script> 